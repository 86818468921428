import PageLayout from '../Layouts/PageLayout'
import { observer } from 'mobx-react'
import LeftSidebar from '../LeftSidebar/LeftSidebar'
import { Box, useMediaQuery } from '@mui/material'
import theme, { makeStyles } from '../../core/utils/theme'
import Cubie from './Cubie'

const useStyles = makeStyles()(() => ({
  root: {
    display:'flex',
    height:'100%',
    overflow:'hidden'
  }
}))

const AskCubie = observer(() => {
  const { classes } = useStyles()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <PageLayout>
      <Box className={classes.root}>
        {!isMobile && <LeftSidebar />}
        <Cubie />
      </Box>
    </PageLayout>
  )
})

export default AskCubie
