import React, { ChangeEvent } from 'react'
import { makeStyles } from '../../core/utils/theme';
import checked from '../../assets/checked.svg'

const useStyles = makeStyles()((theme) => ({
  formInput: {
    flexShrink: 0,
    width: 18,
    height: 18,
    WebkitAppearance: 'none',
    color: theme.palette.text.primary,

    '&:checked': {
      background: `url(${checked})`,
      backgroundPosition: 'center',
      border: 'none',
    },
  },
}))

interface ICheckbox {
  label: string;
  labelColor?: string;
  isSelected: boolean | undefined
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void
  style?: object
}
const Checkbox: React.FC<ICheckbox> = ({ label, isSelected = false, handleChange, labelColor, style={} }) => {
  const { classes } = useStyles()

  if (label) {
    return (
      <label className={`flex items-center px-1 whitespace-wrap mb-2 font-normal ${labelColor}`} style={style}>
        <input
          checked={isSelected}
          type="checkbox"
          className={`${classes.formInput} mr-2 border-2 border-[#0A191C]`}
          onChange={handleChange}
        />
        {label}
      </label>
    )
  }

  return (
    <input
      checked={isSelected}
      type="checkbox"
      className={`${classes.formInput} mr-2 border-2 border-[#0A191C]`}
      onChange={handleChange}
      style={style}
    />
  )
}

export default Checkbox
