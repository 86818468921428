import { Box, Button, Typography } from '@mui/material'
import { observer } from 'mobx-react'
import { FC, MouseEvent, useEffect, useState} from 'react'
import { makeStyles } from '../../core/utils/theme'
import useApp from '../../hooks/useApp'
import useSettings from '../../hooks/useSettings'
import useUser from '../../hooks/useUser'
import AnswerSection from './AnswerSection'
import QuestionField from './QuestionField'
import { isEndUserView } from '../../core/utils/main'
import loadinggif from '../../assets/loading.gif'
import { SampleQuestions } from './SampleQuestions2'
import {ThoughtsInNewWindow, ThoughtsInMain} from './Thoughts'

const useStyles = makeStyles()((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    maxHeight: '100%',
    padding:'0 20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    transition: 'all 0.3s ease-in-out',
    justifyContent: 'flex-start',
    flex:'1',
    position:'relative'
  },
  popoutLink: {
    display:'flex',
    flexDirection:'column',
    justifyContent:'center',
    fontStyle:'italic',
    cursor:'pointer',
    color:'blue',
    textTransform: 'none',
    fontSize:'inherit'
  },
  saved: {
    border:"1px solid black",
    padding:"1px 2px",
    margin:'0 6px 0',
  },
  notSaved: {
    border:"1px solid black",
    padding:"1px 2px",
    margin:'0 6px 0',
    cursor: 'pointer',
  },
  idContainer: {
    display:'flex',
    background:'white',
    padding: '0 8px 0 8px',
    fontSize: '0.75rem',
    '& span': {
      display:'flex',
      flexDirection:'column',
      justifyContent:'center'  
    }
  },
  idText: {
    display:'flex',
    flexDirection:'column',
    justifyContent:'center'
  },
  answerContainer: {
    width:'100%',
    display:'flex',
    flexDirection:'column',
    flex:'1 0',
    overflow:'hidden'
  },
  answerWrapper: {
    width: '100%',
    height: '100%',
    flex: '1 0',
    border: '1px solid black',
    borderBottom: 'none',
  },
  thoughtWrapper: {
    width: '100%',
    height: '100%',
    flex: '1 0',
    border: '1px solid grey',
    borderBottom: 'none',
  },
  shareButton: {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%'
  },
  thinkingLoader: {
    margin: '8px 0px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '1.25rem',
    lineHeight: '1.75rem',
    color: theme.palette.text.primary,
  },
  thinkingLoaderSpinner: {
    display: 'block',
    width: '10rem',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
}))

const copy_to_clipboard = async (e: MouseEvent) => {
  const c2cText = "copied to clipboard"
  if (window.isSecureContext) {
    const elt = e.target as HTMLElement
    if (elt.innerText == c2cText)
      return
    const oldtxt = elt.innerText
    await navigator.clipboard.writeText(oldtxt)
    elt.innerText = c2cText
    setTimeout(()=>{elt.innerText = oldtxt}, 1000)
  } else {
    alert("Copy to clipboard is not available when window.isSecureContext is false.")
  }
}

const IdElement: FC<{idType:string}> = observer(({idType}) => {
  const app = useApp()
  const user = useUser()
  const { classes } = useStyles()
  console.log("IdElement", idType)
  if (user.isInsider() && (idType == 'session' ? app.sessionId : app.queryId))
    return (
      <Box className={classes.idContainer}>
        <span className={classes.idText}>{idType == 'session' ? 'session_id' : 'query_id'}: &nbsp;</span>
        <span className={`${classes.idText} cursor-pointer`} onClick={copy_to_clipboard}>{idType == 'session' ? app.sessionId : app.queryId}</span>
      </Box>
    )
  else
    return <Box></Box>
})

const SaveToDashboard: FC<{show: boolean, isSaved: boolean}> = ({show, isSaved}) => {
  const app = useApp()
  const { classes } = useStyles()

  const handleSaveToDashboard = (e: MouseEvent) => {
    if (isSaved)
      return
    e.preventDefault()
    e.stopPropagation()
    if (app.queryId)
      app.saveToDashboard(app.cakeId, app.queryId, e.target as HTMLElement)
  }
  
  if (show)
    return (isSaved) 
              ? <Box className={classes.saved}>Saved</Box>
              : <Box className={classes.notSaved} onClick={(e)=>handleSaveToDashboard(e)}>Save to Dashboard</Box>
  else
      return <Box></Box>
}

const Cubie: FC = observer(() => {
  const app = useApp()
  const settings = useSettings()
  const user = useUser()
  const { classes } = useStyles()

  const [showSaveToDashboard, setShowSaveToDashboard] = useState<boolean>(false)
  const isSaved = app.dashboardItems.map(x=>x.query_id).includes(app.queryId as string) || app.tempDashboardItems.map(x=>x.query_id).includes(app.queryId as string)
  
  useEffect(() => {
    console.log('cake ID', app.cakeId)
    const showS2DB = Boolean(app.answer && app.queryId && (!isEndUserView() || app.cakeId=='nhanes_fd' || user.isInsider() || !user.isAuthenticated()))
    setShowSaveToDashboard(showS2DB)
  }, [app.answer, app.queryId])

  const handleBake = () => {
    settings.hasDataSources
      ? settings.setIsCreateDatacakeModalOpen(true)
      : settings.setIsDataSourcesModalOpen(true)
  }

  const popout = (e: MouseEvent) => {
    e.preventDefault()
    app.openThoughtsOpenedInNewWindow()
  }

  const shareDatacake = (e: MouseEvent) => {
    e.stopPropagation()
    settings.setIsShareModalOpen(true)
  }

  const submitQuestion = (value: string) => {
    app.setInput(value)
    app.obtainAnswer()
  }

  if (!settings.isLoading && !isEndUserView() && !app.cakeId)
    return (
      <Box className='min-h-40 flex items-center justify-center'>
        <Button className="w-[300px] bg-black px-4 py-1.5 text-white hover:bg-black" onClick={handleBake}>
          + Bake your first Datacake
        </Button>
      </Box>
    )

  return (
      <Box className={classes.root}>
        <Box className="w-full">
          {app.cakeId &&
            <div style={{margin:'10px 0 5px', textAlign:'center'}}>
              <span className="mt-[5px] h-[100%] inline">
                <Typography className="inline-block" variant="h6">
                  <span className={(user.isAuthenticated() && !isEndUserView()) ? 'cursor-pointer' : ''}
                    onClick={()=>{if (user.isAuthenticated() && !isEndUserView()) settings.setIsDatacakesModalOpen(true)}}
                  >
                    {app.cakeName ? app.cakeName : app.cakeId}
                  </span>
                  {app.isDashboardLoading && 
                    <img src={loadinggif} style={{display:'inline', height:'20px', marginLeft:'10px'}}/>
                  }
                </Typography>
              </span>
            </div>
          }
        </Box>
        <Box className="w-full">
          <Box className="h-[20px] w-full flex justify-between">
            <IdElement idType='session'/>
            <Button variant='outlined' className={classes.shareButton} id="dashboardShareButton" onClick={shareDatacake}>Share Datacake</Button>
          </Box>
        </Box>
        <Box className="w-full">
          <QuestionField autoFocus disabled={app.isThinking} onFinish={submitQuestion} placeholder="Ask a question about your data..."/>
        </Box>
        {!app.isThinking && !app.answer && settings.sampleQuestions && (
          <Box className="flex flex-col w-full">
            <SampleQuestions />
          </Box>
        )}
        {app.isThinking && app.isThoughtsOpenedInNewWindow && (
          <Box className={classes.answerContainer}>
            <Box className={classes.thoughtWrapper}>
              <Box className={classes.thinkingLoader}><img src={loadinggif} style={{height:'50px', marginTop:'40px'}}/></Box>
              {app.thoughtsText?.length > 0 && <ThoughtsInNewWindow />}
            </Box>
          </Box>
        )}
        {app.isThinking && !app.isThoughtsOpenedInNewWindow && (
          (app.thoughtsText?.length == 0)
            ? <Box className={classes.thinkingLoader}><img src={loadinggif} style={{height:'50px'}}/></Box>
            : <Box className={classes.answerContainer}>
                <Box className={classes.thoughtWrapper}>
                  <ThoughtsInMain />
                </Box>
              </Box>
        )}
        {app.answer &&
          <Box className="w-full mb-2 h-[20px] text-xs text-black flex justify-between align-center">
            <Box className={classes.popoutLink} style={{visibility: app.isThoughtsOpenedInNewWindow ? 'hidden': 'visible'}} onClick={popout}>Review Cubie's thoughts...</Box>
            <IdElement idType='query' />
            <SaveToDashboard show={showSaveToDashboard} isSaved={isSaved}/>
          </Box>
        }
        {app.isThoughtsOpenedInNewWindow && <ThoughtsInNewWindow />}
        {app.answer && 
          <Box className={classes.answerContainer}>
              <Box className={classes.answerWrapper}>
                <AnswerSection answer={app.answer} />
              </Box>
          </Box>
        }
      </Box>
  )
})
export default Cubie
