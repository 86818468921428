import { useState, useEffect, useRef } from 'react'
import { makeStyles } from '../../core/utils/theme'
import { Box, Typography } from '@mui/material'
import { postCSVFiles, deleteCSVFiles, fetchCSVFiles } from '../../core/services/source_service'
import useSettings from '../../hooks/useSettings'
import useUser from '../../hooks/useUser'
import DeleteIcon from '@mui/icons-material/Delete'
import Loader from '../Misc/Loader'

const useStyles = makeStyles()((theme) => ({
  root: {
    width: '100%',
    minHeight: '500px',
    maxHeight: '80vh',
    position:'relative',
    overflow:'auto'
  },
  innerContainer: {
    height: '100%',
    width: '100%',
    position:'relative',
    display:'flex',
    flexDirection:'column',
    flexGrow: '0',
    overflow:'auto'
  },
  titleContainer: {
    width: '100%',
    display:'flex',
    flexDirection:'column',
    flexGrow:'0'
  },
  input: {
    margin: '1rem 0',
  },
  uploadContainer: {
    display: 'flex',
    flexDirection:'column',
    flex: '1 0',
    alignItems: 'flex-start', // Align items to the start of the container
    width: '100%', // Ensure the container takes the full width
    margin: '1rem 0 0', // Add vertical spacing
  },
  filename: {
    marginRight: '1rem', // Adjust the spacing as needed
    color: 'black'
  },
  uploadButton: {
    flex: 1,
    backgroundColor: theme.palette.primary.main, // Or any color that stands out
    color: '#ffffff', // Usually, a text on a button is white for better contrast
    fontWeight: 'bold', // Makes the text bold
    '&:hover': {
      backgroundColor: theme.palette.primary.dark, // Darker shade for hover state
      color: '#ffffff', // Keep text color white on hover for contrast
    },
    '&:disabled': {
      backgroundColor: theme.palette.action.disabledBackground, // Style for disabled state
      color: theme.palette.action.disabled, // Text color for disabled state
    },
  },
  uploadingAnimation: {
    color: '#ffa500', // Or any other color
    // Define your animation here
    animation: '$blinkingText 1.5s linear infinite',
  },

  // Add a keyframes animation
  '@keyframes blinkingText': {
    '0%': {
      opacity: 1,
    },
    '50%': {
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    },
  },
  fileTableTitle: {
    display:'flex',
    flexGrow: '0',
    flexShrink: '0'
  },
  fileTableContainer: {
    flex: '1 0',
    overflow: 'auto'
  },
  fileTable: {
    width: '100%',
    '& th, & td': {
      border: '1px solid #ddd',
      padding: '80px',
      textAlign: 'left',
    },
    '& th': {
      backgroundColor: '#f2f2f2',
    },
    '& tr:nth-child(even)': {
      backgroundColor: '#f9f9f9',
    },
    // Column width adjustments
    '& th:nth-child(1)': {
      width: '40px', // Adjust the width for the trash can column
    },
    '& th:nth-child(2)': {
      width: 'calc(100% - 340px)', // Adjust the width for the filename column
    },
    '& th:nth-child(3)': {
      width: '300px', // Adjust the width for the header options column
    },
  },
  radioButtonLabel: {
    color: 'black', // Set the color you want for the label text
    marginRight: '8px', // Add some right margin for spacing between the radio buttons
  },
  fileUploader: {
    position: 'relative',
    overflow: 'hidden',
    margin: '1rem 0 0 1rem',
    display:'flex',
  },
  customFileInput: {
    opacity: 0,
    width: '0.1px',
    height: '0.1px',
    position: 'absolute',
    overflow: 'hidden',
    zIndex: -1,
  },
  uploadBtn: {
    backgroundColor: 'white',
    color: 'black',
    padding: '5px 10px',
    border: '1px solid black',
    cursor: 'pointer',
    display: 'inline-block',
    fontSize: '16px',
    transition: 'background-color 0.3s',
    '&:hover': {
      backgroundColor: '#f0f0f0',
    },
  }
}))

const DataSourceManageCSV = () => {
  const { classes } = useStyles()
  const [files, setFiles] = useState<File[]>([])
  const [error, setError] = useState<string | null>(null)
  const [uploadSuccess, setUploadSuccess] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [folderName] = useState('')
  const [savedfiles, setSavedFiles] = useState<String[]>([])
  const [fileHeaders, setFileHeaders] = useState<Record<string, boolean>>({})
  const user = useUser()
  const settings = useSettings()

  const fileInputRef = useRef<HTMLInputElement>(null)

  const handleDeleteFile = async (filenameToDelete:string) => {
    const formData = new FormData()
    formData.append('folder_name', folderName) // Append the folder name
    formData.append('filenames', filenameToDelete) // Append the filename to be deleted
  
    try {
      console.log('Deleting file:', filenameToDelete)
      const response = await deleteCSVFiles(formData)
      if (response.status === "ok") {
        const remainingFiles = savedfiles.filter(filename => filename !== filenameToDelete)
        setSavedFiles(remainingFiles) // Update the saved files state
  
        console.log('File deleted successfully')
        setUploadSuccess(false) // Set the upload success state
        settings.loadDataSources()
      } else {
        console.error('Failed to delete file')
        setError('Delete failed: ' + (response || 'Unknown error'))
      }
    } catch (error) {
      console.error('Error deleting file:', error)
      setError('Delete failed: ' + (error || 'Unknown error'))
    }
  }

  const fetchFiles = async () => {
    try {
      console.log('fetching csv files')
      const response = await fetchCSVFiles()
      console.log('response', response)

      // Extract filenames and headers from the response and update the states
      const filenames = response.data.map((file: { filename: string; hasheader: boolean }) => file.filename)
      const headers = response.data.reduce((acc: Record<string, boolean>, file: { filename: string; hasheader: boolean }) => {
        acc[file.filename] = file.hasheader
        return acc
      }, {})

      setSavedFiles(filenames)
      setFileHeaders(headers)
      setUploadSuccess(false)
      setIsLoading(false)
    } catch (error) {
      // Handle the error
      console.error('Error fetching csv filenames:', error)
    }
  }

  useEffect(() => {
    if (user.isAuthenticated()) {
      
      console.log("fetching customer ids")

      setIsLoading(true)
      // Call the async function
      fetchFiles()
    }
  }, [])

  // once user pick the file, immediately upload the file
  useEffect(() => {
    if (files.length > 0) {
      handleUpload()
    }
  }, [files])

  const maxFileSize = 40 * 1024 * 1024// 40 MB size limit

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const pickedFiles = event.target.files
    if (pickedFiles) {
      const newFiles = Array.from(pickedFiles).filter(file => {
        if (file.size > maxFileSize) {
          // Alert the user or handle the error as needed
          setError("File size should not exceed " + maxFileSize + " MB")
          return false// This will exclude the file from the setFiles array
        }
        console.log("newFile: " + file.name)
        return true
      })

      setFiles(newFiles)
      setUploadSuccess(false)
      settings.loadDataSources
    }
  }

  const handleHeaderRadioChange = (filename: string, hasHeader: boolean) => {
    setFileHeaders((prevHeaders) => ({
      ...prevHeaders,
      [filename as string]: hasHeader,
    }))
  }

  const handleUpload = async () => {
    setError('')
    if (files.length === 0) return

    setIsUploading(true)// Set uploading to true when the upload starts
    setUploadSuccess(false)

    const formData = new FormData()
    formData.append('folder_name', folderName) // Append the random folder name as hidden field
    files.forEach((file) => {
      formData.append('files', file)
    })

    try {
      console.log('Uploading files:', formData)
      const response = await postCSVFiles(formData)

      if (response.status === 'ok') {
        console.log('Files uploaded successfully:', response)
        setUploadSuccess(true) // Set the upload success state
        setError(null) // Clear any previous errors

        // Set the saved with folder_id and filenames after successful upload
        const expiryDate = new Date()
        expiryDate.setDate(expiryDate.getDate() + 7)
        const filenames = files.map(file => file.name).filter(name => name.trim() !== '')
        const allFilenamesSet = new Set([...savedfiles, ...filenames]) // Set will remove duplicates
        const allFilenames = Array.from(allFilenamesSet).join(',') // Convert back to array and join

        setSavedFiles(allFilenames.split(',')) // Update the saved files state

        if (fileInputRef.current) {
          fileInputRef.current.value = ''  // Clear the file input after successful upload
        }
        setFiles([]) // Clear the files array after successful upload

      } else {
        // Handle non-'ok' status
        setError('Upload failed: ' + (response || 'Unknown error'))
        setUploadSuccess(false)
      }
    } catch (error) {
      console.error('Error uploading files:', error)
      setError('Error uploading files: ' + error) // Set the error message
      console.log('Error set to:', String(error))
      setUploadSuccess(false)

    } finally {
      setIsUploading(false) // Set uploading to false when the upload ends
      fetchFiles()
      settings.setFetchNeededForTables(true)
      settings.loadDataSources()
    }
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.innerContainer}>
        <Box className={classes.titleContainer}>
          <Typography variant="h6">Upload Your CSV Files</Typography>
          <Typography variant="body2">
            Upload your CSV files to create new tables in your database. Max size of each file is 40MB.
          </Typography>
        </Box>
        <div className={classes.fileUploader}>
          {user.isRob() ? 
          <input
            type="file"
            // accept=".csv"
            className={classes.customFileInput}
            onChange={handleFileChange}
            id="fileInput"
            ref={fileInputRef}
          /> :
          <input
            type="file"
            accept=".csv"
            className={classes.customFileInput}
            onChange={handleFileChange}
            id="fileInput"
            ref={fileInputRef}
          />
          }
          <label htmlFor="fileInput" className={classes.uploadBtn}>
            Upload File
          </label>
        </div>
        <div className="flex flex-row justify-center">
          {isLoading && <Loader />}
        </div>
        {uploadSuccess && (
          <div className={classes.uploadContainer}>
            <div style={{ color: 'green' }}>Upload successful!</div>
          </div>

        )}
        {isUploading && (
          <div className={classes.uploadContainer}>
            <div className={classes.uploadingAnimation}>Uploading...</div>
          </div>
        )}
        {error && <div className={classes.uploadContainer} style={{ color: 'red' }}>{String(error)}</div>}
        {user.isAuthenticated() && savedfiles && savedfiles.length > 0 && (
          <div className={classes.uploadContainer}>
            <div className={classes.fileTableTitle} style={{ color: 'green' }}>Uploaded files:</div>
            <div className={classes.fileTableContainer}>
              <table className={classes.fileTable}>
                <thead>
                  <tr>
                    <th></th>
                    <th>Filename</th>
                    <th>Header Options</th>
                  </tr>
                </thead>
                <tbody>
                  {savedfiles.map((filename, index) => (
                    <tr key={index}>
                      <td>
                      <DeleteIcon
                          onClick={() => handleDeleteFile(filename.toString())}
                          style={{ cursor: 'pointer', color: 'black' }} 
                        />
                      </td>
                      <td><span className={classes.filename}>{filename}</span></td>
                      <td>
                        <label className={classes.radioButtonLabel}>
                          <input
                            type="radio"
                            name={`header-${filename}`}
                            checked={fileHeaders[filename.toString()] !== false}
                            onChange={() => handleHeaderRadioChange(filename.toString(), true)}
                            style={{ marginRight: '8px' }}
                          />
                          Contains Header
                        </label>
                        <label className={classes.radioButtonLabel}>
                          <input
                            type="radio"
                            name={`header-${filename}`}
                            checked={fileHeaders[filename.toString()] === false}
                            onChange={() => handleHeaderRadioChange(filename.toString(), false)}
                            style={{ marginRight: '8px' }}
                          />
                          No Header
                        </label>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </Box>
    </Box>
  )
}

export default DataSourceManageCSV