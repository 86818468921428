import { FC, useEffect, useState } from 'react'
import PageLayout from '../Layouts/PageLayout'
import  theme, { makeStyles } from '../../core/utils/theme'
import { observer } from 'mobx-react'
import useApp from '../../hooks/useApp'
import useUser from '../../hooks/useUser'
import useSettings from '../../hooks/useSettings'
import { useNavigate } from 'react-router-dom'
import { isEndUserView } from '../../core/utils/main'
import { IDashboardItem } from '../../core/types/code_service/IDashboardItem'
// import HTMLChart from '../AskCubie/HtmlChart'

import { Box, Button, Grid, Typography, useMediaQuery } from '@mui/material'
import LeftSidebar from '../LeftSidebar/LeftSidebar'

import AddIcon from '@mui/icons-material/Add'
import { LoadingSpinner } from '../Misc/Loader'
import SlideOverlay from './SlideOverlay'
import DashboardItem from './DashboardItem'

const useStyles = makeStyles()((theme) => ({
  root: {
    display:'flex',
    height:'100%'
  },
  dashboardContainer: {
    width: '100%',
    height: '100%',
    padding: '0 20px',
    overflow: 'auto'
  },
  dashboardRoot:{
    alignItems: 'center',
    overflowY: 'auto',
    width:'100%'
  },
  dashboardTitle: {
    margin:'10px 0 5px',
    textAlign:'center'
  },
  dashboardTopControls: {
    height:'30px',
    display:'flex',
    justifyContent:'end',
    alignContent:'center',
  },
  dashboardGridWrapper: {
    width: '650px',
    [theme.breakpoints.up(1650)]: {
      width: '1300px',
    },
    [theme.breakpoints.up(2330)]: {
      width: '1980px',
    },
    display:'flex',
    justifyContent:'center',
  },
  
  newItemBox: {
    margin:'20px',
    width:'608px',
    height:'608px',
    display:'flex',
    flexDirection:'column',
    justifyContent:'center',
    alignItems:'center',
    border:'1px solid #999',
    color: '#999',
    cursor: 'pointer',
    '& .hiddenChild': {
      // visibility:'hidden'
    },
    '&:hover': {
      color: 'black',
      border:'1px solid black',
      '& .hiddenChild': {
        // visibility:'visible'
      }
    }
  },
  newItemBoxText: {

  },
  slideOverlayContainer: {
    height: '100%',

  },
  slideOverlayScreen: {
    position: 'absolute',
    top:'0',
    bottom:'0',
    right:'0',
    left:'0',
    background:'white',
    zIndex:'5',
    alignItems:'center',
    backdropFilter: 'blur(12px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent:'center',
    alignContent:'center'
  },
  slideContainer: {
    width:'90%',
    maxWidth:'1400px',
    aspectRatio:'16/10',
    margin: 'auto auto',
    background:'#def',
    border: '1px solid black',
    position:'relative',
    display:'flex',
    alignContent:'center',
  },
  slideAnswerContainer: {
    width: '90%',
    maxWidth:'1200px',
    aspectRatio: '16/10',
    margin: 'auto auto',
    background: '#def',
    border: '1px solid black',
  }
}))

interface IProps {
  openOverlay: (items: IDashboardItem[], i: number) => void
}

const DashboardItems: FC<IProps> = observer(({openOverlay}) => {
  const app = useApp()
  const settings = useSettings()
  const user = useUser()
  const { classes } = useStyles()
  const dashboardItems = app.dashboardItems.concat(app.tempDashboardItems)
  const navigate = useNavigate()

  useEffect(() => {
    if (!window.location.pathname.includes('dashboard'))
      navigate(isEndUserView() ? `/dashboard?cakeId=${app.cakeId}` : `/dashboard` )
  }, [app.cakeId])

  const handlers = {
    delete: async (query_id: string) => {
              const r = await app.deleteDashboardItem(app.cakeId, query_id)
              console.log('r',r)
              app.loadDashboardItems(app.cakeId)
            },
    reorder: (currentPosition: number, newPosition: number) => {
              if (newPosition < 1 || newPosition > app.dashboardItems.length || currentPosition === newPosition) {
                return;
              }
              const updatedItems = [...app.dashboardItems];
              const [movedItem] = updatedItems.splice(currentPosition - 1, 1)
              updatedItems.splice(newPosition - 1, 0, movedItem)
              app.setDashboardItems(updatedItems)
              app.saveDashboardOrder()
            },
    expand: (i: number) => {
              openOverlay(dashboardItems, i)
            }
  }
  
  const newDashboardItemClick = () => {
    console.log("newDBItemClick")
    app.prepareForQuestion(false)
    app.setInput('')
    navigate(isEndUserView() ? `/ask?cakeId=${app.cakeId}` : '/ask' )
  }

  return (
    <Box className={classes.dashboardRoot}>
      {// Title Bar
        app.cakeId && 
          <Box className={classes.dashboardTitle}>
            {app.isDashboardLoading && <LoadingSpinner className="inline h-[20px] mx-[10px]"/> }
            <span className="mt-[5px] h-[100%] inline">
              <Typography className="inline-block" variant="h6">
                <span
                  className={(user.isAuthenticated() && !isEndUserView()) ? 'cursor-pointer' : ''}
                  onClick={()=>{if (user.isAuthenticated() && !isEndUserView()) settings.setIsDatacakesModalOpen(true)}}
                >
                  {app.cakeName ? app.cakeName : app.cakeId}
                </span>
              </Typography>
            </span>
            {app.isDashboardLoading && <LoadingSpinner className="inline h-[20px] mx-[10px]"/> }
          </Box>
      }

      { // Top Controls
       !app.isDashboardLoading && app.dashboardItems && app.dashboardItems.length > 0 &&
        <Box className={classes.dashboardTopControls}>
          <>
            <Button variant='outlined' className="mt-2 cursor-pointer mr-4" id="dashboardShareButton" onClick={(e)=>{e.stopPropagation(); settings.setIsShareModalOpen(true)}}>Share Dashboard</Button>
            {/* <Button variant='outlined' className="mt-2 cursor-pointer" onClick={(e) => rerunAll(e)}>Re-run ALL analyses</Button> */}
          </>
        </Box>
      }

      { // Main Dashboard Content
        <Box className="flex justify-center">
          <Box className={classes.dashboardGridWrapper}>
            <Grid container>
              {dashboardItems.map((item: IDashboardItem, i: number) => (
                <DashboardItem key={item.query_id} item={item} i={i} numItems={dashboardItems.length} handlers={handlers} />
              ))}
              <Box className="relative">
                <Grid item id='dashboardAddNewItem' className={classes.newItemBox} onClick={newDashboardItemClick}>
                  <div style={{visibility:'hidden'}}>New Dashboard Item</div>
                  <div><AddIcon fontSize='large'/></div>
                  <div className="hiddenChild">New Dashboard Item</div>
                </Grid>
              </Box>
            </Grid>
          </Box>
        </Box>
      }
    </Box>
  )
})

const Dashboard: FC = observer(() => {
  const { classes } = useStyles()
  const [overlayItems, setOverlayItems] = useState<IDashboardItem[]|null>(null)
  const [currentItemIndex, setCurrentItemIndex] = useState<number>(0)
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const openOverlay = (dbItems: IDashboardItem[], index: number) => {
    setOverlayItems(dbItems)
    setCurrentItemIndex(index)
  } 

  const closeOverlay = () => {
    setOverlayItems(null)
  }

  return (
    <PageLayout>
      <Box className={classes.root}>
        {!isMobile && <LeftSidebar />}
        <Box className={classes.slideOverlayContainer}>
          {overlayItems && <SlideOverlay overlayItems={overlayItems} currentItemIndex={currentItemIndex} closeOverlay={closeOverlay} setCurrentItemIndex={setCurrentItemIndex}/>}
        </Box>
        <Box className={classes.dashboardContainer}>
          <DashboardItems openOverlay={openOverlay} />
        </Box>
      </Box>
    </PageLayout>
  )
})

export default Dashboard
